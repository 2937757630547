import React, { useEffect } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import sal from 'sal.js';
import '../styles/sal.css';

// Components
import ThreeTwo from '../images/three-two';

const ProjectContainer = styled.div`
  position: relative;
  grid-column-end: ${props => props.column};
  grid-row-end: ${props => props.rowColumn};

  & .slide-up-animation {
    width: 100%;
    height: 100%;
  }

  & .inner-container {
    overflow: hidden;

    width: 100%;
    height: 100%;
  }

  & a {
    display: block;
  }

  &:hover .title {
    opacity: 1;
    transform: translateY(0);
  }

  & img {
    z-index: 2;
  }
`;

const Title = styled.div`
  position: relative;
  z-index: 10;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;

  width: 100%;

  padding: 10px 0;
  color: #4c4c4c;

  & > div {
    width: calc(100% - 25px);
    line-height: 1.25;
  }

  & h2 {
    font-size: 22px;
  }

  & h3 {
    font-size: 20px;
  }

  @media (max-width: 768px) {
    & h2 {
      font-size: 14px;
    }

    & h3 {
      font-size: 12px;
    }
  }
`;

export const Article = ({ project, index }) => {
  useEffect(() => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      sal({
        threshold: 0.2,
        once: true,
      });
    }
  }, []);

  return (
    <ProjectContainer
      column={
        project.node.data.image_size === 'Small' ||
        project.node.data.image_size === 'Portrait'
          ? `span 1`
          : `span 2`
      }
      rowColumn={
        project.node.data.image_size === 'Portrait' ||
        project.node.data.image_size === 'Large'
          ? `span 2`
          : `span 1`
      }
    >
      <div
        data-sal="slide-up"
        data-sal-duration="450"
        data-sal-easing="ease-out-quad"
        className="slide-up-animation"
      >
        <Link to={`/long-reads/${project.node.uid}`}>
          <div className="inner-container">
            <ThreeTwo
              image={project.node.data.image.url}
              setSize={project.node.data.image_size}
              alt={project.node.data.image.alt}
              width={project.node.data.image.dimensions.width}
              height={project.node.data.image.dimensions.height}
            />
          </div>

          <Title className="title">
            <div>
              <h2>{project.node.data.long_read_title.text}</h2>
              <h3>{project.node.data.subtitle.text}</h3>
            </div>
          </Title>
        </Link>
      </div>
    </ProjectContainer>
  );
};
