import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

// Components
import { Article } from '../components/long-reads/article';

const WorkContainer = styled.div`
  position: relative;

  padding: 60px 0 0 0;
  margin: 0 auto;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 45px;
  grid-auto-flow: dense;

  @media (min-width: 1500px) {
    max-width: 1400px;
  }

  @media (max-width: 1499px) {
    max-width: 1050px;
  }

  @media (max-width: 767px) {
    padding: 15px 0 0 0;

    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
  }
`;

const LongReads = ({ data }) => {
  const projects = data.allPrismicLongRead.edges.map((project, index) => (
    <Article
      project={project}
      index={index}
      key={`project_${project.node.prismicId}_${index}`}
    />
  ));

  return (
    <>
      <Helmet title={`Long Reads - Treatment Studio`} />
      <WorkContainer>{projects}</WorkContainer>
    </>
  );
};

export default withPrismicPreview(LongReads);

export const query = graphql`
  {
    allPrismicLongRead(sort: { fields: data___date, order: DESC }) {
      edges {
        node {
          uid
          prismicId
          data {
            long_read_title {
              text
            }
            subtitle {
              text
            }
            image {
              alt
              url(imgixParams: { auto: "format" })
              dimensions {
                width
                height
              }
            }
            image_size
          }
        }
      }
    }
  }
`;
